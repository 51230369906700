<template>
  <b-container fluid>
    <b-overlay
      :show="isLoading"
      :opacity="0.5"
      bg-color="white"
      spinner-variant="secondary"
      rounded="sm"
      z-index="1000"
    >
      <b-row class="treeselect-container" v-if="isInitialized">
        <b-col lg="12" md="12" sm="12">
          <p class="m-2">Menu permissions:</p>
          <div class="h-400">
            <treeselect
              class="treeselect"
              v-model="menuTree.value"
              z-index="1"
              :multiple="true"
              open-direction="bottom"
              :always-open="true"
              :options="menuTree.options"
              :default-expand-level="1"
              @select="onMenuTreeSelect"
              @deselect="onMenuTreeDeselect"
              search-nested
            >
              <label
                slot="option-label"
                slot-scope="{ node }"
                :class="[hasComponentPermissions(node.id) ? 'bold' : '']"
              >
                {{ node.label }}
              </label>
            </treeselect>
          </div>
        </b-col>

        <b-col lg="12" md="12" sm="12">
          <component-permissions
            :branch="menuTree.selectedBranch"
            @update="onComponentPermissionsUpdate"
          ></component-permissions>
        </b-col>
      </b-row>

      <br />
    </b-overlay>

    <b-row v-if="isEmbeded">
      <hr />

      <form-submission-actions
        :mode="mode"
        :loading="{ save: saveInProgress, saveAndView: saveAndViewInProgress }"
        @save="
          save('tabular').then(response =>
            response ? $router.push({ name: 'Users' }) : false
          )
        "
        @save-and-view="
          save('view').then(response =>
            response
              ? $router.push({
                  name: 'User submission',
                  params: { action: 'view', id: response }
                })
              : false
          )
        "
        @edit="
          $router.push({
            name: 'User submission',
            params: { action: 'edit', id: userId }
          })
        "
        @back="$router.push($store.getters['router/previousRoute'])"
      />
    </b-row>
  </b-container>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import FormSubmissionActions from '@/components/FormSubmissionActions'
import ComponentPermissions from './ComponentPermissions.vue'

import { buildMenuFromRouter } from '@/shared/permissions'

import { mapState, mapActions } from 'vuex'

export default {
  props: {
    roleId: {
      type: [String],
      default: ''
    },
    userId: {
      type: [String, Number],
      default: ''
    },

    mode: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    isEmbeded: {
      type: Boolean,
      default: true
    }
  },
  name: 'MenuPermissionsTree',
  components: {
    Treeselect,
    FormSubmissionActions,
    ComponentPermissions
  },
  data: function () {
    return {
      entityId: undefined,
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      isInitialized: false,
      controls: {},
      menuTree: {
        selectedBranch: null,
        value: null,
        options: []
      },
      permissionsData: [],
      componentsWithPermissions: []
    }
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created () {},
  mounted () {
    this.initialize({ roleId: this.roleId, userId: this.userId })
  },
  methods: {
    ...mapActions('permissions', [
      'fetchRolePermissions',
      'fetchUserPermissions',
      'updateRolePermissions',
      'updateUserPermissions'
    ]),
    async initialize (payload) {
      this.entityId = payload.roleId ? payload.roleId : payload.userId

      if (!this.entityId) return

      let menu = []

      menu = buildMenuFromRouter(
        menu,
        '',
        this.$router.options.routes[2].children,
        true
      )

      this.drawTreeSelectStructure(menu)

      this.isLoading = true

      let response = this.roleId
        ? await this.fetchRolePermissions(this.roleId)
        : await this.fetchUserPermissions(this.userId)

      this.isLoading = false

      this.permissionsData = []

      const propName = this.roleId ? 'permissions' : 'user_permissions'

      this.permissionsData = this.$helpers.getJSONObject(response[propName])

      this.menuTree.value = []

      if (this.permissionsData) {
        this.applyPermissions2Menu(this.menuTree.options, this.permissionsData)
      }

      this.isInitialized = true
    },
    hasComponentPermissions (id) {
      return this.componentsWithPermissions.includes(id)
    },
    applyPermissions2Menu (menuItems, permissionsData) {
      //go througth menu tree, if leaf name equals to route name select it
      menuItems.forEach(item => {
        let p = permissionsData
          .filter(p => p.path !== '')
          .find(p => p.path == item.url && p.route_name == item.label)

        if (p) {
          //apply component permissions stored in the db to menuTree object
          if (p.component_permissions && p.component_permissions.length > 0) {
            item.component_permissions = p.component_permissions
            this.componentsWithPermissions.push(item.id)
          }

          this.menuTree.value.push(item.id)
        }

        if (item.children && item.children.length > 0)
          this.applyPermissions2Menu(item.children, permissionsData)
      })
    },
    drawTreeSelectStructure (menu) {
      let self = this

      let menuTree = [],
        categoryId

      menu.forEach(item => {
        let itemId = this.$helpers.uuidv4()

        let treeItem = {
          id: itemId,
          label: item.name,
          url: item.url,
          component_permissions: []
        }

        //if category then pull next items to childrens until next category
        if (item.title) {
          categoryId = itemId
        }

        let children = []

        if (item.children) {
          item.children.forEach(c => {
            //if 3rd level exists...
            let nestedChildren = []
            if (c.children) {
              nestedChildren = c.children.map(c => ({
                id: this.$helpers.uuidv4(),
                label: c.name,
                url: c.url,
                component_permissions: []
              }))
            }

            let cm = {
              id: this.$helpers.uuidv4(),
              label: c.name,
              url: c.url,
              component_permissions: []
            }

            if (nestedChildren.length) cm.children = nestedChildren

            children.push(cm)
          })

          treeItem.children = children
        }

        if (categoryId && !item.title) {
          let category = menuTree.find(c => c.id == categoryId)

          if (!category.children) category.children = []

          category.children.push(treeItem)
        } else {
          menuTree.push(treeItem)
        }
      })

      self.menuTree.options = menuTree
    },
    onMenuTreeSelect (v) {
      this.menuTree.selectedBranch = v
    },
    onMenuTreeDeselect () {
      this.menuTree.selectedBranch = undefined
    },
    async updateMenuPermissions () {
      if (!this.entityId) return

      let self = this,
        selectedBranches = []

      this.menuTree.value.forEach(v => {
        let result = this.getSelectedBranches(self.menuTree.options, v)

        //remove duplicates
        result.forEach(item => {
          if (
            !selectedBranches.find(
              b => b.route_name == item.route_name && b.path == item.path
            )
          ) {
            selectedBranches.push(item)
          }
        })
      })

      selectedBranches = [...new Set(selectedBranches)]

      let payload = {
        id: this.entityId,
        data: JSON.stringify(selectedBranches)
      }

      let response
      try {
        response = this.roleId
          ? await this.updateRolePermissions(payload)
          : await this.updateUserPermissions(payload)
      } catch (error) {
        console.log(error)
      }

      this.$form.makeToastInfo(response.message)

      return response
      /*
      let url = this.roleId
        ? `role/${this.entityId}/permissions`
        : `user/${this.entityId}/permissions`;

      return this.$api
        .put(url, payload)
        .then(response => {
          // self.$form.makeToastInfo(response.message);

          return true;
        })
        .catch(error => {
          this.isLoading = false;

          self.$form.makeToastError(error.message);

          return false;
        });
        */
    },
    getSelectedBranches (rootItems, id) {
      let result = []

      let item = rootItems.find(i => i.id == id)

      //if item not found go through all childrens
      if (!item) {
        for (let i = 0; i < rootItems.length; i++) {
          if (rootItems[i].children) {
            let recursionResult = this.getSelectedBranches(
              rootItems[i].children,
              id
            )

            if (recursionResult.length) {
              let item = rootItems[i]
              let obj = {
                route_name: item.label,
                path: item.url,
                component_permissions: item.component_permissions,
                children: []
              }
              return [obj, ...recursionResult]
            }
          }
        }
      }
      //if item found collect all childrens
      else {
        let obj = {
          route_name: item.label,
          path: item.url,
          component_permissions: item.component_permissions,
          children: []
        }

        if (!result.find(o => o.route_name == obj.route_name)) result.push(obj)

        if (item.children) {
          let childrenBranches = this.collectChildrenBranches(item)

          result = [...result, ...childrenBranches]
        }

        return result
      }

      return result
    },
    collectChildrenBranches (root) {
      let result = []

      if (!root.children) return

      for (let i = 0; i < root.children.length; i++) {
        let item = root.children[i]

        let obj = {
          route_name: item.label,
          path: item.url,
          children: [],
          component_permissions: item.component_permissions
        }

        if (!result.find(o => o.route_name == obj.route_name)) result.push(obj)

        //if item has childrens then go through them
        if (item.children) {
          let recursionResult = this.collectChildrenBranches(item)

          if (recursionResult.length) result = [...result, ...recursionResult]
        }
      }

      return result
    },

    save (_mode) {
      this.saveInProgress = _mode == 'tabular'

      this.saveAndViewInProgress = _mode == 'view'
      /*
      return this.updateMenuPermissions()
        .then(() => {
          this.saveInProgress = true;
          this.saveAndViewInProgress = true;
          //self.$form.makeToastInfo(response.message);
          return this.user_id;
        });
        */
    },

    onComponentPermissionsUpdate (e) {
      this.updateBranchComponentPermissions(this.menuTree.options, e)

      this.updateMenuPermissions()
    },
    updateBranchComponentPermissions (items, e) {
      //find element and update
      for (let i = 0; i < items.length; i++) {
        let item = items[i]

        if (e.branch.url && item.url == e.branch.url) {
          item.component_permissions = e.value

          this.componentsWithPermissions.push(item.id)

          return true
        }

        if (item.children && item.children.length > 0) {
          let result = this.updateBranchComponentPermissions(item.children, e)
          if (result) return true
        }
      }
    }
  },
  watch: {
    userId (newValue) {
      this.initialize({
        userId: newValue
      })
    },
    roleId (newValue) {
      this.initialize({
        roleId: newValue
      })
    },

    'menuTree.value' (newVal, oldVal) {
      if (!this.$_.isEqual(oldVal, newVal)) {
        this.updateMenuPermissions()
      }
    },
    mode () {
      this.updateControlsState()
    }
  }
}
</script>

<style scoped>
.bold {
  padding-top: 0.5em;
  font-weight: bold;
}
.treeselect-container {
  height: 100%;
}
.h-400 {
  height: 400px;
}

.treeselect-container >>> .vue-treeselect__multi-value {
  height: 100px !important;
  overflow: auto;
}
</style>
