var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "opacity": 0.5,
      "bg-color": "white",
      "spinner-variant": "secondary",
      "rounded": "sm",
      "z-index": "1000"
    }
  }, [_vm.isInitialized ? _c('b-row', {
    staticClass: "treeselect-container"
  }, [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('p', {
    staticClass: "m-2"
  }, [_vm._v("Menu permissions:")]), _c('div', {
    staticClass: "h-400"
  }, [_c('treeselect', {
    staticClass: "treeselect",
    attrs: {
      "z-index": "1",
      "multiple": true,
      "open-direction": "bottom",
      "always-open": true,
      "options": _vm.menuTree.options,
      "default-expand-level": 1,
      "search-nested": ""
    },
    on: {
      "select": _vm.onMenuTreeSelect,
      "deselect": _vm.onMenuTreeDeselect
    },
    scopedSlots: _vm._u([{
      key: "option-label",
      fn: function fn(_ref) {
        var node = _ref.node;
        return _c('label', {
          class: [_vm.hasComponentPermissions(node.id) ? 'bold' : '']
        }, [_vm._v(" " + _vm._s(node.label) + " ")]);
      }
    }], null, false, 1252134938),
    model: {
      value: _vm.menuTree.value,
      callback: function callback($$v) {
        _vm.$set(_vm.menuTree, "value", $$v);
      },
      expression: "menuTree.value"
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('component-permissions', {
    attrs: {
      "branch": _vm.menuTree.selectedBranch
    },
    on: {
      "update": _vm.onComponentPermissionsUpdate
    }
  })], 1)], 1) : _vm._e(), _c('br')], 1), _vm.isEmbeded ? _c('b-row', [_c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      }
    },
    on: {
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Users'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'User submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'User submission',
          params: {
            action: 'edit',
            id: _vm.userId
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }