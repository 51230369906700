<template>
  <div class="animated fadeIn" v-if="branch">
    <inline-input
      id=""
      :value="componentPermissions"
      :label="`${componentName} permissions (click outside the box to save)`"
      :readonly="false"
      :mode="$constants.FORM_MODE.EDIT"
      :required="false"
      :is-text-area="true"
      :rows="20"
      @changed="updateField"
    />
  </div>
</template>

<script>
import InlineInput from "@/components/InlineInput";

export default {
  props: {
    branch: {
      type: Object,
      default: () => {}
    }
  },
  name: "ComponentPermissions",
  components: {
    InlineInput
  },
  data: function() {
    return {
      value: ""
    };
  },
  computed: {
    componentName() {
      return this.branch ? this.branch.label : "";
    },
    componentPermissions() {
      return JSON.stringify(this.branch.component_permissions);
    }
  },
  mounted() {},
  methods: {
    updateField(e, value) {
      let valueObject = undefined;

      try {
        valueObject = JSON.parse(value);
        this.$emit("update", { branch: this.branch, value: valueObject });
      } catch {
        this.$form.makeToastError("Provided value is not valid JSON");
      }
    }
  }
};
</script>

<style scoped></style>
