var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.branch ? _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('inline-input', {
    attrs: {
      "id": "",
      "value": _vm.componentPermissions,
      "label": "".concat(_vm.componentName, " permissions (click outside the box to save)"),
      "readonly": false,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": false,
      "is-text-area": true,
      "rows": 20
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }